import { Component } from "react"
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI


    
 
class RecorrerFechasCmp extends Component {    

    componentDidMount(){
        const script = document.createElement("script");
        script.src = "https://awesome-table.com/AwesomeTableInclude.js";
        script.async = true;
        document.body.appendChild(script);        
    }

    render() {
        const {            
            titleIcon=<span><strong>Recorrer fechas</strong><br/></span>,
            txtTitle=<span>
                        <ol>
                            <li>Busca si el cliente esta en el listado.</li>
                            <li>Da click en el boton autorizar para especificar el numero de cuotas a recorrer.</li>
                        </ol>
                        <br/><br/>
                    </span>
            
                    } = this.props
        return (
            <div>
                <br/><br/>
            <CardElevation  fullWidth>                
                <br/><br/>
                <Grid container >
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h4" >
                        <strong style={{color:'#698d21'}}>{titleIcon}</strong>                        
                        </Typography>                       
                        
                    </Grid>
                
                <br/><br/>
               
                <Grid item xs={12} sm={12}>
                    
                    <Typography component='h6' variant="h6" >
                        {txtTitle}                       
                    </Typography>                                                   
                    <div data-type="AwesomeTableView" data-viewID="-M2pP-zDBtCzGsI3R1bw" style={{width:"100%"}}></div>
                    
                    <br/><br/>
                    
                </Grid>
                
                </Grid>
            </CardElevation>
        </div>
        )
    }
}

RecorrerFechasCmp.propTypes = {      
    titleIcon: PropTypes.string, 
    txtTitle: PropTypes.string
}

export default withThemeProps(RecorrerFechasCmp, 'VDPNRecorrerFechas')






